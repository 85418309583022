<script lang="ts">
  import { twMerge as merge } from 'tailwind-merge';

  type TextTheme = 'dark' | 'light' | 'white';
  type ListStyle = 'default' | 'checkmark';
  type $$Props = {
    text?: string;
    fieldId?: string;
    entityId?: string;
    theme?: TextTheme;
    class?: string;
    listStyle?: ListStyle;
  };

  export let text: string | undefined = undefined;
  export let fieldId: string | undefined = undefined;
  export let entityId: string | undefined = undefined;
  export let theme: TextTheme = 'dark';
  export let listStyle: ListStyle = 'default';

  let className = '';
  export { className as class };

  const proseThemes = {
    dark: 'prose-dark',
    light: 'prose-light',
    white: 'prose-white',
  };
</script>

<!-- eslint-disable svelte/no-at-html-tags -->
{#if text}
  <div
    data-contentful-entry-id={entityId}
    data-contentful-field-id={fieldId}
    class={merge('prose max-w-none', proseThemes[theme], listStyle, className)}
  >
    <slot>{@html text}</slot>
  </div>
{/if}

<style lang="postcss">
  :global(.prose p) {
    font-size: 20px;
    line-height: 180%;
  }

  :global(.prose a) {
    color: #34d399;
    text-decoration: none;
  }

  :global(.prose a:hover) {
    text-decoration: underline;
  }

  :global(.prose blockquote) {
    @apply py-8 border-0;
    background: #1f203f;
    quotes: none;
    font-style: normal;
  }

  :global(.prose blockquote::before) {
    content: url('/images/quote.svg');
  }

  :global(.prose blockquote p) {
    font-size: 32px;
    line-height: 160%;
  }

  :global(.prose iframe) {
    width: 100%;
    height: clamp(260px, 32vw, 520px);
  }

  :global(.prose.checkmark ul > li) {
    list-style-image: url('/images/checkmark.svg');
  }
</style>
